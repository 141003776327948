import * as React from "react"
import { Grid, Typography, makeStyles, Select, MenuItem } from "@material-ui/core"
import { useState } from "react"

interface FilterProps {
  topics: Set<string>;
  onTopicChange: (string) => void;
}

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(5),
    width: "100%",
    top: theme.spacing(5),
    position: "sticky",
  },
  heading: {
    fontSize: "0.80rem",
  },
  topic: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    textTransform: "capitalize",
    cursor: "pointer",
  },
  selectedTopic: {
    backgroundColor: theme.palette.primary[50],
    color: theme.palette.primary.main,
    borderRadius: "5px",
  },
  hideBelowMedium: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  hideAboveSmall: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
}))

const Filters = ({ topics, onTopicChange }: FilterProps) => {
  const sortedTopics = Array.from(topics).sort()
  const classes = useStyles()
  const [selectedTopic, setSelectedTopic] = useState<string>(sortedTopics[0])
  const handleTopicChange = topic => {
    setSelectedTopic(topic)
    onTopicChange(topic)
  }

  return <Grid container className={classes.container} justify={"center"}>
    <Grid item className={classes.hideBelowMedium}>
      <SidebarFilter sortedTopics={sortedTopics}
                     selectedTopic={selectedTopic}
                     onChange={handleTopicChange}
                     classes={classes}
      />
    </Grid>
    <Grid item xs={11} className={classes.hideAboveSmall}>
      <DropDownFilter sortedTopics={sortedTopics}
                      selectedTopic={selectedTopic}
                      onChange={handleTopicChange}
                      classes={classes}
      />
    </Grid>
  </Grid>
}

const SidebarFilter = ({ classes, sortedTopics, selectedTopic, onChange }) => {
  return <Grid container direction={"column"}>
    <Grid item>
      <Typography variant={"overline"} className={classes.heading} color={"textSecondary"}>Browse By</Typography>
    </Grid>
    {sortedTopics.map(topic => <Grid item key={topic}>
      <div onClick={() => onChange(topic)}
           className={topic === selectedTopic ? classes.selectedTopic : ""}
      >
        <Typography variant={"subtitle1"} className={classes.topic}>
          {topic}
        </Typography>
      </div>
    </Grid>)}
  </Grid>
}

const DropDownFilter = ({ classes, sortedTopics, selectedTopic, onChange }) => {
  return <Grid container justify={"center"}>
    <Grid item>
      <Select
        value={selectedTopic}
        onChange={e => onChange(e.target.value)}
      >
        {sortedTopics.map(topic => <MenuItem value={topic} key={topic}>
          <Typography variant={"subtitle1"} className={classes.topic}>
            {topic}
          </Typography>
        </MenuItem>)}
      </Select>
    </Grid>
  </Grid>
}

export default Filters