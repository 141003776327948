import React from "react"
import { graphql, StaticQuery } from "gatsby"
import CardWithButton from "../Cards/CardWithButton"
import Card from "../Cards/Card"

export const CreatePost = ({ excerpt, title, slug, buttonLabel }) => {
  let imageName = slug.replace(/\//g, "")
  return (
    <StaticQuery
      query={query}
      render={data => {
        const photo = data.allFile.edges.filter(
          edge => edge.node.name === imageName,
        )[0]
        return (
          <>
            {buttonLabel &&
            <CardWithButton photo={photo} excerpt={excerpt} title={title} linkTo={slug} buttonLabel={buttonLabel}/>}
            {!buttonLabel && <Card photo={photo} excerpt={excerpt} title={title} linkTo={slug}/>}
          </>
        )
      }}
    />
  )
}

export const query = graphql`
  query {
    allFile(
      filter: {
        extension: { eq: "jpeg" }
      }
    ) {
      edges {
        node {
          name
          sourceInstanceName
          childImageSharp {
            fluid {
              src
              sizes
              srcSet
              base64
              aspectRatio
            }
          }
        }
      }
    }
  }
`
