import { Grid, makeStyles, Typography } from "@material-ui/core"
import { Link, Node } from "gatsby"
import Img from "gatsby-image"
import * as React from "react"

interface CardProps {
  photo: string | Node;
  title: string;
  photoHeight?: number;
  linkToUrl?: string;
  excerpt?: string;
  alignCardItems?: "center" | "flex-start";
  alignExcerpt?: "center" | "left";
  children?: JSX.Element;
}

const Card = ({
                photo, excerpt, title, linkToUrl,
                alignCardItems = "flex-start",
                alignExcerpt = "left",
                photoHeight = 200,
                children,
              }
                : CardProps) => {
  const classes = useStyles()
  return (
    <Grid container direction={"column"} alignItems={alignCardItems} spacing={2}>
      {typeof (photo) === "object" &&
      <Grid item className={classes.imageContainer}>
        <Link to={linkToUrl} aria-label={title}>
          <Img
            fluid={(photo as Node).node["childImageSharp"].fluid}
            alt={title}
            className={classes.image}
            loading={"lazy"}
          />
        </Link>
      </Grid>
      }
      {
        typeof (photo) === "string" &&
        <Grid item>
          {linkToUrl &&
          <Link to={linkToUrl} aria-label={title}>
            <img alt={title} loading={"lazy"} src={photo as string} style={{ height: photoHeight }}/>
          </Link>}

          {!linkToUrl && <img alt={title} loading={"lazy"} src={photo as string} style={{ height: photoHeight }}/>}
        </Grid>

      }
      <Grid item>
        <Typography variant={"h6"}>{title}</Typography>
      </Grid>
      {
        excerpt && <Grid item>
          <Typography variant={"body2"} align={alignExcerpt}>{excerpt}</Typography>
        </Grid>
      }
      {
        children && <Grid item>
          {children}
        </Grid>
      }
    </Grid>
  )
}

// @ts-ignore
const useStyles = makeStyles(() => {
  return ({
    // https://jsfiddle.net/w6hya98L/8/
    imageContainer: {
      position: "relative !important",
      width: "100% !important",
      height: "100% !important",
      overflow: "hidden",
    },
    image: {
      width: "100% !important",
      height: "100% !important",
    },
  })
})

export default Card
