import { Grid, Button } from "@material-ui/core"
import React from "react"
import Card from "./Card"
import { navigate } from "gatsby"

const CardWithButton = ({ photo, excerpt, title, linkTo, buttonLabel }) => {
  return <Grid container spacing={2}>
    <Grid item>
      <Card photo={photo} excerpt={excerpt} title={title} linkTo={linkTo}/>
    </Grid>
    <Grid item>
      <Button
        variant={"contained"}
        color={"primary"}
        onClick={() => navigate(linkTo)}
        aria-label={title}
      >
        {buttonLabel}
      </Button>
    </Grid>
  </Grid>
}

export default CardWithButton;