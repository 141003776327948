import { Grid, useTheme } from "@material-ui/core"
import { PageTitles } from "../../shared/constants"
import PageTitleDescription from "../Common/PageTitleDescription"
import * as React from "react"
import ShowAllPosts from "../Posts/ShowAllPosts"
import { graphql, useStaticQuery } from "gatsby"
import Filters from "./Filters"
import { useState } from "react"
import useMediaQuery from "@material-ui/core/useMediaQuery"

const ALL_TOPICS: string = "ALL"

export const Learn = () => {
  const data = useStaticQuery(query)
  const [postsData, setPostsData] = useState(data.allMdx.edges || [])

  const topics: Set<string> = new Set(data.allMdx.edges.map(edge => edge.node.frontmatter.topics).flat())
  topics.add(ALL_TOPICS)

  const onTopicChange = topic => {
    if (topic === ALL_TOPICS) {
      setPostsData(data.allMdx.edges)
      return
    }
    const filteredPosts = data.allMdx.edges.filter(edge => edge.node.frontmatter.topics.includes(topic))
    setPostsData(filteredPosts)
  }

  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.down("sm"))

  return <Grid container justify="center">
    <Grid item xs={12}>
      <PageTitleDescription
        title={PageTitles.learn.title}
        description={PageTitles.learn.description}
      />
    </Grid>
    <Grid item xs={12}>
      <Grid container justify={"space-between"}>
        <Grid item xs={12} md={2}>
          <Filters topics={topics} onTopicChange={onTopicChange}/>
        </Grid>
        <Grid item xs={12} md={9}>
          <ShowAllPosts alignPosts={matches ? "center" : "flex-start"} edges={postsData} showInCard={true}/>
        </Grid>
      </Grid>
    </Grid>
  </Grid>
}

export const query = graphql`
  query {
    allMdx(filter: {fileAbsolutePath: {regex: "/(posts|articles)/"}}, sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          id
          fileAbsolutePath
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            authors
            topics
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`