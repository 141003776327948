import { navigate } from "gatsby"
import { Divider, Grid, makeStyles } from "@material-ui/core"
import { CreatePost } from "./CreatePost"
import * as React from "react"
import HoverCard from "../Cards/HoverCard"

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(5),
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}))

interface ShowAllPostsProps {
  // todo (h2): find the correct type for data
  edges: [];
  alignPosts: "flex-start" | "center";
  showInCard?: boolean;
}

const ShowAllPosts = ({ edges, alignPosts, showInCard = false }: ShowAllPostsProps) => {
  const classes = useStyles()

  return (
    <Grid container justify={alignPosts} className={classes.container}>
      {edges.map((edge, key) => {
        if (!showInCard) {
          return <ShowAsPost
            key={edge["node"]["title"]}
            excerpt={edge["node"]["excerpt"]}
            title={edge["node"]["frontmatter"]["title"]}
            slug={edge["node"]["fields"]["slug"]}
            fileAbsolutePath={edge["node"]["fileAbsolutePath"]}
            classes={classes}/>
        }

        return (
          <HoverCard key={key} onClick={() => navigate(edge["node"]["fields"]["slug"])}>
            <Grid item key={key}>
              <CreatePost
                excerpt={edge["node"]["excerpt"]}
                title={edge["node"]["frontmatter"]["title"]}
                slug={edge["node"]["fields"]["slug"]}
              />
            </Grid>
          </HoverCard>
        )
      })}
    </Grid>
  )
}

const ShowAsPost = ({ excerpt, title, slug, fileAbsolutePath, classes }) => {
  const buttonLabel = fileAbsolutePath.includes("/articles/") ? "READ" : "WATCH"
  return <Grid item xs={10}>
    <CreatePost
      excerpt={excerpt}
      title={title}
      slug={slug}
      buttonLabel={buttonLabel}
    />
    <Divider className={classes.divider}/>
  </Grid>
}

export default ShowAllPosts