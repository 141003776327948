import * as React from "react"
import { Grid, makeStyles } from "@material-ui/core"

// Adopted from https://codepen.io/ovdojoey/pen/BomKyZ
// todo (h2): Make it responsive
const useStyles = makeStyles(theme => ({
  outer: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    borderRadius: "6px",
    width: "400px",
    border: "1px solid #fff",
    transition: "box-shadow 0.3s",

    "&:hover": {
      border: "1px solid #ccc",
      boxShadow: "0 0 11px rgba(33,33,33,.2)",
      cursor: "pointer",
    },
  },
}))

type CardProps = {
  onClick: () => void;
  children: JSX.Element;
};

/**
 * A general-purpose Card that shows border and box-shadow on hover
 * @param children
 * @param onClick
 * @constructor
 */
const HoverCard = ({ children, onClick }: CardProps) => {
  const classes = useStyles()

  return <Grid container className={classes.outer} onClick={onClick}>
    <Grid item>
      {children}
    </Grid>
  </Grid>
}

export default HoverCard