import React from "react"
import Layout from "../components/layout"
import SEO from "../components/SEO"
import { seoData } from "../shared/seoData"
import { Learn } from "../components/Learn"

export default class LearnContainer extends React.Component {
  render() {
    const seo = seoData.learnPage
    return (
      <>
        <SEO
          title={seo.title}
          description={seo.metaDescription}
          pathname={seo.pathname}
        />
        <Layout selectedTabValue={0}>
          <Learn />
        </Layout>
      </>
    )
  }
}
